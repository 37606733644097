import React, { Component } from 'react';

class Recommendation extends Component {
  render() {
    const { logo, name, url, children, company, companyUrl } = this.props;
    return (
      <div className="col-9">
        <img style={{ backgroundColor: "white" }} src={logo} alt={name} className="picture" />
        <a href={url} className="sub-title">{name}</a>
        <a href={companyUrl} className="company">@{company}</a>
        <p className="content">{children}</p>
      </div>
    );
  }
}

export default Recommendation;
