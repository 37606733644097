import React from 'react';

import Recommendation from '../components/recommendation'
import {Container} from '../components/commons';
import SEO from '../components/seo'

import recommendations from '../data/recommendations.json'

const TestimonialsSection = () => {
  const recommendationList = recommendations.map(r => (
    <Recommendation key={r.name} {...r}>
      {r.content}
    </Recommendation>
  ))

  return (
    <>
      <SEO
        title="Testimonials"
        keywords={['gatsby', 'portfolio', 'naveen kumar sangi', 'naveenkumarsangi']} />
      <Container id="words" className="words">
        <div className="row">
          {recommendationList}
        </div>
      </Container>
    </>
  )
};

export default TestimonialsSection;
